import React from "react";
import ReactDOM from "react-dom";
import * as lib from "./lib";
import { flatMap } from "lodash";

import { injectGlobal, css } from "emotion";

function preloadImage(url) {
  var img = new Image();
  img.src = url;
}

preloadImage(require("./images/onboard_chose_rule_option_1.png"));
preloadImage(require("./images/onboard_pick_a_rule.png"));
preloadImage(require("./images/onboard_undo.png"));

injectGlobal`
  body {
    background: url(${require("./paper-bg.png")}) rgb(255, 255, 252);
    overflow-y: scroll;
  }

  @font-face {
    font-family: 'dejavu_sans_monobook';
    src: url('${require("./fonts/DejaVuSansMono-webfont.woff")}') format('woff');
    font-weight: normal;
    font-style: normal;
  }

  @keyframes fadeIn { 
    from { opacity: 0.1; } 
    to { opacity: 0.7; }
  }
  
  .animate-flicker {
      opacity: 0.7;
      animation: fadeIn 1s infinite alternate;
  }

  a {
    text-decoration: none;
    font-weight: 600;
    color: #212121;

    &:hover {
      background-color: rgba(0, 0, 255, 0.08);
      border-radius: 4px;
    }
  }
`;

const RULES = [
  {
    name: "Rule 1",
    premise: "xI",
    conclusion: "xIU",
    apply: lib.rule1
  },
  {
    name: "Rule 2",
    premise: "Mx",
    conclusion: "Mxx",
    apply: lib.rule2
  },
  {
    name: "Rule 3",
    premise: "xIIIy",
    highlightedPremise: (
      <span>
        x
        <span style={{ background: "rgb(178, 235, 242)", borderRadius: "4px" }}>
          III
        </span>
        y
      </span>
    ),
    conclusion: "xUy",
    apply: lib.rule3
  },
  {
    name: "Rule 4",
    premise: "xUUy",
    highlightedPremise: (
      <span>
        x
        <span style={{ background: "rgb(178, 235, 242)", borderRadius: "4px" }}>
          UU
        </span>
        y
      </span>
    ),
    conclusion: "xy",
    apply: lib.rule4
  }
];

const LARGE_FONT_SIZE = 55;
const CAPTION_FONT_SIZE = 14;

function FormulaSegment({ text, highlight }) {
  return (
    <svg
      width="800px"
      height={`68px`}
      viewBox={`0 0 800 68`}
      className={css`
        margin: 8px 0;
      `}
    >
      <g transform="translate(400,55)">
        <g
          transform={`translate(${(-text.length * LARGE_FONT_SIZE * 0.6) /
            2}, 0)`}
        >
          {highlight && (
            <rect
              className={css``}
              x={highlight.from * LARGE_FONT_SIZE * 0.6}
              y={-46}
              width={(highlight.to - highlight.from) * LARGE_FONT_SIZE * 0.6}
              height={52}
              rx={4}
              ry={4}
              fill="#B2EBF2"
            />
          )}
          <text className="text">{text}</text>
        </g>
      </g>
    </svg>
  );
}

function ArrowSegment() {
  return (
    <svg width="800px" height="60px" viewBox="0 0 800 60">
      <g transform="translate(400,0)">
        <g fill="#212121">
          <path d="M0 0 L6 6 L1 6 L1 60 L-1 60 L-1 6 L-6 6 L0 0" />
        </g>
      </g>
    </svg>
  );
}

function LineSegment() {
  return (
    <svg width="800px" height="60px" viewBox="0 0 800 60">
      <g transform="translate(400,0)">
        <g fill="#212121">
          <path d="M0 0 L1 0 L1 60 L-1 60 L-1 0 L0 0" />
        </g>
      </g>
    </svg>
  );
}

function RulesSegment({ onSelect, unapplicableRulesIndices, showOnboarding }) {
  const ruleStyle = css`
    display: block;
    margin: 0 6px;
    padding: 12px 16px;

    border: solid 1px transparent;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    color: black;
    position: relative;
    &:hover {
      background: none;
    }

    &:hover b {
      background-color: rgba(0, 0, 255, 0.08);
      border-radius: 4px;
    }
  `;
  return (
    <div
      className={css`
        text-align: center;
        position: relative;
        margin: 30px 0;
      `}
    >
      {showOnboarding && (
        <div
          className={css`
            width: 160px;
            height: 160px;
            background: url(${require("./images/onboard_pick_a_rule.png")})
              no-repeat;
            background-size: contain;
            position: absolute;
            left: -132px;
            top: -80px;
          `}
        />
      )}
      <div
        className={css`
          display: flex;
        `}
      >
        {RULES.map((rule, i) => (
          <div
            key={`rule-segment-rule-${i}`}
            className={css`
              position: relative;
              flex: 1;
              text-align: center;
            `}
          >
            <div
              href="#"
              className={ruleStyle}
              style={{
                opacity: unapplicableRulesIndices.has(i) ? 0.5 : 1.0
              }}
              onClick={e => {
                e.preventDefault();
                onSelect(rule, i);
              }}
            >
              <b
                className={css`
                  color: #212121;
                `}
              >
                {rule.name}
              </b>
              <br />
              {rule.premise} &rarr; {rule.conclusion}
            </div>
            {unapplicableRulesIndices.has(i) && (
              <div
                className={css`
                  position: absolute;
                  top: 0;
                  left: 0;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                `}
              >
                <span
                  className={css`
                    background: #ffebee;
                    color: #e53935;
                    border-radius: 4px;
                    transform: rotate(-8deg);
                  `}
                >
                  not applicable
                </span>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

function RuleSegment({ name, premise, conclusion }) {
  return (
    <div
      className={css`
        text-align: center;
        padding: 12px 0;
      `}
    >
      <b>{name}</b>
      <br />
      {premise} &rarr; {conclusion}
    </div>
  );
}

function AxiomSegment() {
  return (
    <div
      className={css`
        text-align: center;
        padding: 12px 0;
      `}
    >
      <b>Axiom</b>
    </div>
  );
}

function TopNavigation({ onBackToIndex }) {
  return (
    <div
      className={css`
        position: absolute;
        box-shadow: 0 0px 6px rgba(16, 22, 26, 0.2);
        top: 0;
        left: 0;
        background: white;
        padding: 8px 12px;
        font-family: Charter;
        border-bottom-right-radius: 8px;
      `}
    >
      <a
        href="#"
        onClick={e => {
          e.preventDefault();
          onBackToIndex();
        }}
      >
        &larr; Back to index
      </a>
    </div>
  );
}

function GameGoal({ goalString }) {
  return (
    <div
      className={css`
        text-align: center;
      `}
    >
      <div
        className={css`
          font-family: Nunito;
          font-size: 24px;
        `}
      >
        Goal
      </div>

      <div
        className={css`
          font-size: 55px;
        `}
      >
        {goalString}
      </div>
    </div>
  );
}

class GameScreen extends React.Component {
  constructor() {
    super();
    this.state = {
      gameState: "select_rule",
      gameStateData: {
        unapplicableRulesIndices: new Set()
      },
      derivationLog: [],
      showSelectRuleOnboarding: true,
      showSelectOptionOnboarding: true,
      showUndoOnboarding: true
    };
  }
  getCurrent() {
    if (this.state.derivationLog.length > 0) {
      return this.state.derivationLog[0].output;
    } else {
      return "MI";
    }
  }
  isSuccessful() {
    return this.getCurrent() === this.props.goalString;
  }
  renderSelectRule() {
    const { showSelectRuleOnboarding } = this.state;
    return (
      <div>
        <div
          className={
            "animate-flicker " +
            css`
              font-size: 55px;
              text-align: center;
              padding: 2.5px 0;
            `
          }
        >
          ?
        </div>

        <ArrowSegment />

        <div
          className={css`
            width: 680px;
            margin: 0 auto;
          `}
        >
          <RulesSegment
            unapplicableRulesIndices={
              this.state.gameStateData.unapplicableRulesIndices
            }
            onSelect={(rule, index) => {
              const options = rule.apply(this.getCurrent());
              if (options.length === 0) {
                this.setState(oldState => ({
                  gameStateData: {
                    unapplicableRulesIndices: oldState.gameStateData.unapplicableRulesIndices.add(
                      index
                    )
                  }
                }));
                return;
              }
              this.setState({
                gameState: "select_option",
                gameStateData: {
                  ruleIndex: index,
                  options: options,
                  selectedOptionIndex: 0
                },
                showSelectRuleOnboarding: false
              });
            }}
            showOnboarding={showSelectRuleOnboarding}
          />
        </div>

        <LineSegment />
      </div>
    );
  }
  renderSelectOption() {
    const { showSelectOptionOnboarding } = this.state;
    const {
      ruleIndex,
      options,
      selectedOptionIndex
    } = this.state.gameStateData;
    const isFirstOption = selectedOptionIndex === 0;
    const isLastOption = selectedOptionIndex === options.length - 1;
    const rule = RULES[ruleIndex];
    const option = options[selectedOptionIndex];

    return (
      <div>
        <div className="animate-flicker">
          <FormulaSegment text={option.output} />
        </div>

        <ArrowSegment />
        <div
          className={css`
            text-align: center;

            position: relative;
          `}
        >
          {showSelectOptionOnboarding && options.length > 1 && (
            <div
              className={css`
                width: 320px;
                height: 320px;
                background: url(${require("./images/onboard_chose_rule_option_1.png")})
                  no-repeat;
                background-size: contain;
                position: absolute;
                right: -42px;
                top: -35px;
              `}
            />
          )}

          <div
            className={css`
              padding: 12px 0;
            `}
          >
            <small>
              {selectedOptionIndex + 1}/{options.length}
            </small>
            <div
              className={css`
                margin-top: 8px;
                display: flex;
                justify-content: center;
              `}
            >
              <div />
              <div
                className={css`
                  width: 46px;
                  text-align: left;
                `}
              >
                {!isFirstOption && (
                  <a
                    href="#"
                    className={css`
                      font-size: 32px;
                      z-index: 10;
                    `}
                    onClick={e => {
                      e.preventDefault();
                      this.setState(oldState => ({
                        gameStateData: Object.assign(
                          {},
                          oldState.gameStateData,
                          {
                            selectedOptionIndex:
                              oldState.gameStateData.selectedOptionIndex - 1
                          }
                        )
                      }));
                    }}
                  >
                    &larr;
                  </a>
                )}
              </div>
              <div>
                <div
                  className={css`
                    margin-bottom: 4px;
                  `}
                >
                  <b>{rule.name}</b>
                </div>
                {rule.highlightedPremise || rule.premise} &rarr;{" "}
                {rule.conclusion}
              </div>
              <div
                className={css`
                  width: 46px;
                  text-align: right;
                `}
              >
                {!isLastOption && (
                  <a
                    href="#"
                    className={css`
                      font-size: 32px;
                      z-index: 10;
                    `}
                    onClick={e => {
                      e.preventDefault();
                      this.setState(oldState => ({
                        gameStateData: Object.assign(
                          {},
                          oldState.gameStateData,
                          {
                            selectedOptionIndex:
                              oldState.gameStateData.selectedOptionIndex + 1
                          }
                        )
                      }));
                    }}
                  >
                    &rarr;
                  </a>
                )}
              </div>
            </div>
            <div
              className={css`
                margin-top: 8px;
              `}
            >
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  this.setState(oldState => ({
                    gameState: "select_rule",
                    gameStateData: { unapplicableRulesIndices: new Set() },
                    derivationLog: [option].concat(oldState.derivationLog),
                    showSelectOptionOnboarding:
                      options.length === 1 &&
                      oldState.showSelectOptionOnboarding
                  }));
                }}
              >
                Apply
              </a>{" "}
              or{" "}
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  this.setState({
                    gameState: "select_rule",
                    gameStateData: { unapplicableRulesIndices: new Set() }
                  });
                }}
              >
                Cancel{" "}
              </a>
            </div>
          </div>

          <LineSegment />
        </div>
      </div>
    );
  }
  renderSuccess() {
    return (
      <div
        className={css`
          text-align: center;
          font-family: Charter;
          width: 400px;

          padding: 32px 0;
          font-size: 1em;
          margin: 0 auto;
        `}
      >
        Congratulations! You have reached the goal.{" "}
        <a
          href="#"
          onClick={e => {
            e.preventDefault();
            this.props.onExit();
          }}
        >
          Click here
        </a>{" "}
        to go back to the index and choose another problem.
      </div>
    );
  }
  render() {
    const { gameState, gameStateData, derivationLog } = this.state;
    return (
      <div
        className={css`
          padding: 32px 0;
        `}
      >
        {derivationLog.length > 0 && (
          <React.Fragment>
            {this.state.showUndoOnboarding && (
              <div
                className={css`
                  width: 220px;
                  height: 220px;
                  background: url(${require("./images/onboard_undo.png")})
                    no-repeat;
                  background-size: contain;
                  position: absolute;
                  right: 20px;
                  top: 2px;
                `}
              />
            )}
            <div
              className={css`
                position: absolute;
                box-shadow: 0 0px 6px rgba(16, 22, 26, 0.2);
                top: 0;
                right: 0;
                background: white;
                padding: 8px 12px;
                font-family: Charter;
                border-bottom-left-radius: 8px;
              `}
            >
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  this.setState(oldState => ({
                    gameState: "select_rule",
                    gameStateData: { unapplicableRulesIndices: new Set() },
                    derivationLog: oldState.derivationLog.slice(1),
                    showUndoOnboarding: false
                  }));
                }}
              >
                Undo
              </a>
            </div>
          </React.Fragment>
        )}
        <TopNavigation onBackToIndex={this.props.onExit} />

        <GameGoal goalString={this.props.goalString} />

        {this.isSuccessful()
          ? this.renderSuccess()
          : gameState === "select_rule"
          ? this.renderSelectRule()
          : gameState === "select_option" && this.renderSelectOption()}

        {derivationLog.map((entry, i) => {
          const rule = RULES[entry.rule_index];
          const isLastEntry = i === 0;
          return (
            <React.Fragment key={`derivation-log-entry-${i}-${entry.output}`}>
              <FormulaSegment
                text={entry.output}
                highlight={
                  isLastEntry &&
                  gameState === "select_option" &&
                  gameStateData.options[gameStateData.selectedOptionIndex]
                    .highlight
                }
              />
              <ArrowSegment />
              <RuleSegment {...rule} />
              <LineSegment />
            </React.Fragment>
          );
        })}

        <FormulaSegment text="MI" />
        <ArrowSegment />
        <AxiomSegment />
      </div>
    );
  }
}

/*
<Diagram
          entries={this.state.entries}
          applicableRuleIndices={applicableRuleIndices}
          onApplyResult={result => {
            this.setState(oldState => ({
              entries: [result].concat(oldState.entries)
            }));
          }}
          currentString={this.getCurrent()}
        />
        */

/*
        <RulesSegment
          onSelectRule={(rule, rule_index) => {
            const results = rule.apply(this.getCurrent());
            this.setState(oldState => ({
              entries: [results[0]].concat(oldState.entries)
            }));
          }}
          applicableRuleIndices={applicableRuleIndices}
        />
*/

let lastRectOutline = null;
window.renderRect = function(rect) {
  var el = document.createElement("div");
  el.style.backgroundColor = "red";
  el.style.opacity = 0.3;
  el.style.position = "absolute";
  el.style.left = rect.x + "px";
  el.style.top = rect.y + "px";
  el.style.width = rect.width + "px";
  el.style.height = rect.height + "px";
  if (lastRectOutline) {
    document.body.removeChild(lastRectOutline);
  }
  lastRectOutline = el;
  document.body.appendChild(el);
};

function WelcomeScreen(props) {
  const problems = [
    "MIU",
    "MUI",
    "MIIII",
    "MIIU",
    "MIUI",
    "MUUIU",
    "MIIUIIU",
    "MUUII",
    "MUIIU",
    "MUUIIUIIU"
  ];
  return (
    <div
      className={css`
        font-family: Charter;
        text-align: left;
        line-height: 1.4;

        font-size: 1.18rem;
        margin-top: 54px;
      `}
    >
      <div>
        <div
          className={css`
            font-size: 1.8em;
            font-weight: 600;
            border-top: solid 4px #212121;
            padding-top: 8px;
            margin-bottom: 32px;
          `}
        >
          Hofstadter's MU Playground
        </div>
        <p>
          Suppose there are the symbols M, I, and U which can be combined to
          produce strings of symbols. The MU puzzle asks one to start with the
          "axiomatic" string MI and transform it into the string MU using in
          each step one of the following transformation rules:
          <div
            className={css`
              display: flex;
              padding: 20px 0;
            `}
          >
            {RULES.map(rule => (
              <div
                className={css`
                  flex: 1;
                  font-family: "dejavu_sans_monobook";
                `}
              >
                <b
                  className={css`
                    color: #212121;
                    font-family: Charter;
                  `}
                >
                  {rule.name}
                </b>
                <br />
                {rule.premise} &rarr; {rule.conclusion}
              </div>
            ))}
          </div>
          where x and y are variables that stand for any string in the language.
        </p>

        <p>
          Can the puzzle be solved? Use this playground to answer the question!
          You will be presented with an interface displaying your <i>goal</i>{" "}
          (the string you should try to derive), your <i>current string</i> (the
          string you have), the <i>derivation log</i> (how you got there from
          the axiom) and the four rules above that you can apply to your current
          string to derive a new string. Sometimes a rule can be applied in
          multiple ways, and in such cases the playground will ask you to pick
          your path.
        </p>

        <p>
          The reading for this section of the course{" "}
          <a href="http://brianrabern.net/hofstadter_mu.pdf" target="_blank">
            is accessible online
          </a>
          .
        </p>

        <p>
          Ready? Pick a problem to get started:{" "}
          {problems.map((problem, i) => (
            <React.Fragment key={`problem-${i}`}>
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  props.onGetStarted(problem);
                }}
              >
                {problem}
              </a>
              ,{" "}
            </React.Fragment>
          ))}
          or{" "}
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              props.onGetStarted(
                problems[Math.floor(Math.random() * problems.length)]
              );
            }}
          >
            attempt a random problem
          </a>
          . If you feel up to it,{" "}
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              props.onGetStarted("MU");
            }}
          >
            give the MU puzzle a try
          </a>
          .
          {/*
          <a
            href="#"
            onClick={e => {
              e.preventDefault();
              props.onGetStarted();
            }}
          >
            Click here
          </a>{" "}
          to get started :-)*/}
        </p>
      </div>

      <div
        className={css`
          margin-top: 42px;
          border-top: solid 1px #212121;
          padding-top: 8px;
        `}
      >
        <a href="http://brianrabern.net/logic1.html" target="_blank">
          main course page
        </a>
      </div>
    </div>
  );
}

class Application extends React.Component {
  constructor() {
    super();
    this.state = {
      screen: "welcome"
    };
  }
  render() {
    if (this.state.screen === "welcome") {
      return (
        <WelcomeScreen
          onGetStarted={goalString => {
            this.setState({
              screen: "game",
              goalString: goalString
            });
          }}
        />
      );
    } else {
      return (
        <GameScreen
          goalString={this.state.goalString}
          onExit={() => {
            this.setState({ screen: "welcome" });
          }}
        />
      );
    }
  }
}

//transform="translate(114.4,52)"

ReactDOM.render(
  <div>
    <Application />
  </div>,
  document.getElementById("root")
);
