/*

input string ->
[{
    input: "...",
    result: "...",
    bindings: {
        x: {
            sources: [{from: 0, to: 2}],
            targets: [{from: 1, to: 3}]
        }
    }
}]

*/

// xI -> xIU
export function rule1(input) {
  if (input.endsWith("I")) {
    return [
      {
        rule_index: 0,
        input: input,
        output: input + "U",
        bindings: {
          x: {
            sources: [{ from: 0, to: input.length - 1 }],
            targets: [{ from: 0, to: input.length - 1 }]
          }
        },
        highlight: null
      }
    ];
  }
  return [];
}

// Mx → Mxx
export function rule2(input) {
  if (input.startsWith("M")) {
    return [
      {
        rule_index: 1,
        input: input,
        output: "M" + input.slice(1) + input.slice(1),
        bindings: {
          x: {
            sources: [{ from: 1, to: input.length }],
            targets: [
              { from: 1, to: input.length },
              { from: input.length, to: input.length * 2 - 1 }
            ]
          }
        },
        highlight: null
      }
    ];
  }
  return [];
}

// xIIIy → xUy
export function rule3(input) {
  const results = [];
  for (let i = 0; i < input.length; i++) {
    if (input.slice(i).startsWith("III")) {
      const x = input.slice(0, i);
      const y = input.slice(i + 3);
      results.push({
        rule_index: 2,
        input: input,
        output: x + "U" + y,
        bindings: {
          x: {
            sources: [{ from: 0, to: i }],
            targets: [{ from: 0, to: i }]
          },
          y: {
            sources: [{ from: i + 3, to: input.length }],
            targets: [{ from: i + 1, to: input.length - 2 }]
          }
        },
        highlight: {
          from: i,
          to: i + 3
        }
      });
    }
  }
  return results;
}

// xUUy → xy
export function rule4(input) {
  const added = new Set();
  const results = [];
  for (let i = 0; i < input.length; i++) {
    if (input.slice(i).startsWith("UU")) {
      const x = input.slice(0, i);
      const y = input.slice(i + 2);
      const output = x + y;
      if (added.has(output)) continue;
      results.push({
        rule_index: 3,
        input: input,
        output: output,
        bindings: {
          x: {
            sources: [{ from: 0, to: i }],
            targets: [{ from: 0, to: i }]
          },
          y: {
            sources: [{ from: i + 2, to: input.length }],
            targets: [{ from: i, to: input.length - 2 }]
          }
        },
        highlight: {
          from: i,
          to: i + 2
        }
      });
      added.add(output);
    }
  }
  return results;
}

window.rules = { rule1, rule2, rule3, rule4 };
